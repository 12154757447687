import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'

const BlogPage = ({ data }) => {
	// console.log(data)
	return (
		<Layout>
			{
				data.allFile.nodes.map(node => (
					<article key={node.childMdx.id}>
						<h2>
							<Link to={`/blog/${node.childMdx.slug}`}>
								{node.childMdx.frontmatter.title}
							</Link>
						</h2>
						<p>Posted: {node.childMdx.frontmatter.date}</p>
					</article>
				))
			}
		</Layout>
	)
}

export const query = graphql`
{
  allFile(
    filter: {sourceInstanceName: {eq: "blog"}, ext: {in: [".mdx", ".md"]}}
    sort: {fields: childrenMdx___frontmatter___date, order: DESC}
  ) {
    nodes {
      childMdx {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
}
`

export default BlogPage